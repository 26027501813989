<template>
  <div>
    <div class="ds-grid">
      <div class="ds-grid__col ">
        <h3 class="ds-heading">
          {{ title }}
        </h3>
      </div>
    </div>
    <div class="ds-grid">
      <!-- FIRST COLUMN: Europe -->
      <div v-if="europeLocations" class="ds-grid__col ds-grid__col--6 ds-md-grid__col--3 ds-grid__col--no-gutter">
        <div class="ds-grid">
          <div class="ds-grid__col ds-grid__col--6 ds-md-grid__col--12">
            <h4 class="ds-heading-xsmall ds-margin-top-48">
              {{ europeLocations.title }}
            </h4>
            <ul>
              <li
                v-for="(item, index) in europeLocations.items"
                :key="`europe-${index}`"
                class="ds-margin-top-12"
              >
                <DsLink
                  v-if="item.url && withLinks"
                  :url="item.url"
                  nuxt
                  :content="item.name"
                  :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
                />
                <span v-else>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- SECOND COLUMN: Asia -->
      <div v-if="asiaLocations" class="ds-grid__col ds-grid__col--6 ds-md-grid__col--3 ds-grid__col--no-gutter">
        <h4 class="ds-heading-xsmall ds-margin-top-48">
          {{ asiaLocations.title }}
        </h4>
        <ul>
          <li
            v-for="(item, index) in asiaLocations.items"
            :key="`asia-${index}`"
            class="ds-margin-top-12"
          >
            <DsLink
              v-if="item.url && withLinks"
              :url="item.url"
              nuxt
              :content="item.name"
              :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
            />
            <span v-else>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <!-- THIRD COLUMN: Americas -->
      <div v-if="northAmericaLocations" class="ds-grid__col ds-grid__col--12 ds-md-grid__col--3 ds-grid__col--no-gutter">
        <div class="ds-grid">
          <div class="ds-grid__col ds-grid__col--6 ds-md-grid__col--12">
            <h4 class="ds-heading-xsmall ds-margin-top-48">
              {{ northAmericaLocations.title }}
            </h4>
            <ul>
              <li
                v-for="(item, index) in northAmericaLocations.items"
                :key="`northAmerica-${index}`"
                class="ds-margin-top-12"
              >
                <DsLink
                  v-if="item.url && withLinks"
                  :url="item.url"
                  nuxt
                  :content="item.name"
                  :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
                />
                <span v-else>{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <div v-if="latinAmericaLocations" class="ds-grid__col ds-grid__col--6 ds-md-grid__col--12">
            <h4 class="ds-heading-xsmall ds-margin-top-48">
              {{ latinAmericaLocations.title }}
            </h4>
            <ul>
              <li
                v-for="(item, index) in latinAmericaLocations.items"
                :key="`latinAmerica-${index}`"
                class="ds-margin-top-12"
              >
                <DsLink
                  v-if="item.url && withLinks"
                  :url="item.url"
                  nuxt
                  :content="item.name"
                  :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
                />
                <span v-else>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- FOURTH COLUMN: middle east -->
      <div v-if="middleEastLocations || oceaniaLocations" class="ds-grid__col ds-grid__col--12 ds-md-grid__col--3 ds-grid__col--no-gutter">
        <div class="ds-grid">
          <div class="ds-grid__col ds-grid__col--6 ds-md-grid__col--12">
            <h4 class="ds-heading-xsmall ds-margin-top-48">
              {{ middleEastLocations.title }}
            </h4>
            <ul>
              <li
                v-for="(item, index) in middleEastLocations.items"
                :key="`middleEast-${index}`"
                class="ds-margin-top-12"
              >
                <DsLink
                  v-if="item.url && withLinks"
                  :url="item.url"
                  nuxt
                  :content="item.name"
                  :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
                />
                <span v-else>{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <div class="ds-grid__col ds-grid__col--6 ds-md-grid__col--12">
            <h4 class="ds-heading-xsmall ds-margin-top-48">
              {{ oceaniaLocations.title }}
            </h4>
            <ul>
              <li
                v-for="(item, index) in oceaniaLocations.items"
                :key="`oceania-${index}`"
                class="ds-margin-top-12"
              >
                <DsLink
                  v-if="item.url && withLinks"
                  :url="item.url"
                  nuxt
                  :content="item.name"
                  :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
                />
                <span v-else>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { DsLink } from 'awds'
const { locale } = useI18n()
const { routes, retrieveRoutes } = useRoutesStore()

const props = defineProps({
  locations: {
    type: Array,
    default: () => ([])
  },
  title: {
    type: String,
    default: ''
  },
  withLinks: {
    type: Boolean,
    default: true
  }
})

// Fetch routes in a non blocking way
retrieveRoutes()

const locationsMap = computed(() => {
  // When routes are retrieved, this composable will update
  const { getInternalUrl } = useRoutes(routes.value, locale)
  const locationsData = new Map()
  const mappedLocations = props.locations
    .map(loc => ({
      id: loc.sys?.id || loc.id,
      name: loc.city?.name,
      region: loc.city?.country?.region || loc.region,
      url: getInternalUrl(loc.sys?.id)
    }))
    .sort((loc1, loc2) => loc1.name?.localeCompare(loc2.name))

  for (const location of mappedLocations) {
    const id = location.region?.uniqueId || location.region?._name
    if (!locationsData.has(id)) { locationsData.set(id, { title: location.region?.name, items: [] }) }
    locationsData.get(id).items.push(location)
  }

  return locationsData
})

const europeLocations = computed(() => {
  return locationsMap.value.get('EUROPE')
})
const asiaLocations = computed(() => {
  return locationsMap.value.get('ASIAPACIFIC')
})
const northAmericaLocations = computed(() => {
  return locationsMap.value.get('NORTHAMERICA')
})
const latinAmericaLocations = computed(() => {
  return locationsMap.value.get('LATINAMERICA')
})
const middleEastLocations = computed(() => {
  return locationsMap.value.get('MIDDLEEAST')
})
const oceaniaLocations = computed(() => {
  return locationsMap.value.get('OCEANIA')
})

</script>
